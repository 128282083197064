// ** Logo
import logoPibi from '@src/assets/images/logo/logo-pibi.png'

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <img className="fallback-logo" src={logoPibi} alt="logo" style={{width: '120px'}} />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
