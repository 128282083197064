// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

export const createRoleAssignments = createAsyncThunk(
  'roleAssignments/createRoleAssignments',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.post('/admin/assignments/roles/create', data)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateRoleAssignments = createAsyncThunk(
  'roleAssignments/updateRoleAssignments',
  async (data, {rejectWithValue}) => {
    try {
      await bicloudAxios.put(
        `/admin/assignments/roles/updateAllUserRoles/${data.userId}`,
        data.assignments,
      )

      const r = await bicloudAxios.get(`/admin/assignments/roles/getAllUserRoles/${data.userId}`)

      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateRoleAssignmentsByReport = createAsyncThunk(
  'roleAssignments/updateRoleAssignmentsByReport',
  async (data, {rejectWithValue}) => {
    try {
      await bicloudAxios.put(
        `/admin/assignments/roles/updateAllUserRolesInReport/${data.reportId}`,
        data.assignments,
      )

      const r = await bicloudAxios.get(
        `/admin/assignments/roles/getAllUserRolesInReport/${data.reportId}`,
      )

      return r.data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  },
)

export const getRoleAssignments = createAsyncThunk(
  'roleAssignments/getRoleAssignments',
  async (id, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get(`/admin/assignments/roles/getAllUserRoles/${id}`)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getRoleAssignmentsByReport = createAsyncThunk(
  'roleAssignments/getRoleAssignmentsByReport',
  async (id, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get(`/admin/assignments/roles/getAllUserRolesInReport/${id}`)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const checkRoleAssignments = createAsyncThunk(
  'roleAssignments/checkRoleAssignments',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.post('/admin/assignments/roles/check', data)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const roleAssignmentsSlice = createSlice({
  name: 'roleAssignments',
  initialState: {
    status: null,
  },
  extraReducers: {
    [createRoleAssignments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [createRoleAssignments.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [createRoleAssignments.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateRoleAssignments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateRoleAssignments.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [updateRoleAssignments.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateRoleAssignmentsByReport.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateRoleAssignmentsByReport.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [updateRoleAssignmentsByReport.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getRoleAssignments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getRoleAssignments.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [getRoleAssignments.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getRoleAssignmentsByReport.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getRoleAssignmentsByReport.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [getRoleAssignmentsByReport.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [checkRoleAssignments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [checkRoleAssignments.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [checkRoleAssignments.rejected]: (state, action) => {
      state.status = 'failed'
    },
  },
})

export default roleAssignmentsSlice.reducer
