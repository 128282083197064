// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

export const getRoles = createAsyncThunk(
  'roles/getRoles',
  async (reportData, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get('/admin/roles/getAll')
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createRole = createAsyncThunk('roles/createRole', async (data, {rejectWithValue}) => {
  try {
    const r = await bicloudAxios.post('/admin/roles/createRol', data)
    return r.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateRole = createAsyncThunk('roles/updateRole', async (data, {rejectWithValue}) => {
  try {
    const r = await bicloudAxios.put(`/admin/roles/updateRole/${data.roleId}`, data)
    return r.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const deleteRole = createAsyncThunk('roles/deleteRole', async (id, {rejectWithValue}) => {
  try {
    const r = await bicloudAxios.delete(`/admin/roles/deleteRole/${id}`)
    return {r: r.data, roleId: id}
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const reportsSlice = createSlice({
  name: 'roles',
  initialState: {
    list: [],
    status: 'starting',
    msg: null,
  },
  extraReducers: {
    [getRoles.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getRoles.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [getRoles.rejected]: (state, action) => {
      state.status = 'failed'
      state.msg = {data: action.payload.data, status: action.payload.status}
    },
    [deleteRole.pending]: (state, action) => {
      state.status = 'deletingRole'
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.list = state.list.filter((role) => role.id !== action.payload.roleId)
      state.status = 'success'
    },
    [deleteRole.rejected]: (state, action) => {
      state.status = 'failedDeletingRole'
      state.msg = {data: action.payload.data, status: action.payload.status}
    },
    [createRole.pending]: (state, action) => {
      state.status = 'creatingRol'
    },
    [createRole.fulfilled]: (state, action) => {
      state.list = state.list.filter((role) => role.id !== action.payload.roleId)
      state.status = 'success'
    },
    [createRole.rejected]: (state, action) => {
      state.status = 'failedCreatingRole'
    },
    [updateRole.pending]: (state, action) => {
      state.status = 'UpdatingRol'
    },
    [updateRole.rejected]: (state, action) => {
      state.status = 'failedUpdatingRole'
    },
  },
})

export default reportsSlice.reducer
