// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

export const getReports = createAsyncThunk(
  'reports/getReports',

  async (reportData, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get('/user/reports/getAll')
      console.log(r)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    list: [],
    status: null,
  },
  reducers: {
    deleteMsg(state) {
      console.log('borrar msg')
      // state.msg = null
    },
  },
  extraReducers: {
    [getReports.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getReports.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [getReports.rejected]: (state, action) => {
      state.status = 'failed'
      state.msg = {data: action.payload.data, status: action.payload.status}
    },
  },
})

export default reportsSlice.reducer
