// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import reports from './reports'
import users from './users'
import connectors from './connectors'
import roles from './roles'
import filters from './filters'
import conditions from './conditions'
import roleAssignments from './roleAssignments'
import filterAssignments from './filterAssignments'
import workspaces from './workspaces'
import statistics from './statistics'

const rootReducer = {
  auth,
  navbar,
  layout,
  reports,
  users,
  connectors,
  roles,
  filters,
  roleAssignments,
  filterAssignments,
  conditions,
  workspaces,
  statistics,
}

export default rootReducer
