// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

export const getFilters = createAsyncThunk(
  'filters/getFilters',
  async (reportData, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get('/admin/filters/getAll')
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createFilter = createAsyncThunk(
  'filters/createFilter',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.post('/admin/filters/create', data)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateFilter = createAsyncThunk(
  'filters/updateFilter',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.put(`/admin/filters/update/${data.filterId}`, data)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const deleteFilter = createAsyncThunk(
  'filters/deleteFilter',
  async (id, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.delete(`/admin/filters/delete/${id}`)
      return {r: r.data, filterId: id}
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    list: [],
    status: 'starting',
    msg: null,
  },
  extraReducers: {
    [getFilters.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getFilters.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [getFilters.rejected]: (state, action) => {
      state.status = 'failed'
      state.msg = {data: action.payload.data, status: action.payload.status}
    },
    [createFilter.pending]: (state, action) => {
      state.status = 'creatingFilter'
    },
    [createFilter.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [updateFilter.pending]: (state, action) => {
      state.status = 'updatingFilter'
    },
    [updateFilter.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [deleteFilter.pending]: (state, action) => {
      state.status = 'deletingFilter'
    },
    [deleteFilter.fulfilled]: (state, action) => {
      state.list = state.list.filter((filter) => filter.id !== action.payload.filterId)
      state.status = 'success'
    },
    [deleteFilter.rejected]: (state, action) => {
      state.status = 'failedDeletingFilter'
    },
  },
})

export default filtersSlice.reducer
