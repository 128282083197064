import {createSlice} from '@reduxjs/toolkit'

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    getRecentUsers: [],
    usersWithoutReq: [],
  },
  reducers: {
    getRecentUsers: (state, action) => {
      state.getRecentUsers = action.payload
    },
    getUsersWithoutReq: (state, action) => {
      state.usersWithoutReq = action.payload
    },
  },
})

export const {getRecentUsers, getUsersWithoutReq} = statisticsSlice.actions
export default statisticsSlice.reducer
