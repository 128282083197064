import {createSlice} from '@reduxjs/toolkit'

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: {
    list: [],
    id: [],
  },
  reducers: {
    getAllWorkspace(state, action) {
      state.list = action.payload
    },
    getIDWorkspace(state, action) {
      state.id = action.payload
    },
  },
})

export const {getAllWorkspace, getIDWorkspace} = workspaceSlice.actions
export default workspaceSlice.reducer
