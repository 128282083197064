// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

export const getConditions = createAsyncThunk(
  'conditions/getConditions',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get('/admin/filters/getAllConditions')
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const conditionsSlice = createSlice({
  name: 'conditions',
  initialState: {
    list: [],
    status: null,
  },
  extraReducers: {
    [getConditions.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getConditions.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [getConditions.rejected]: (state, action) => {
      state.status = 'failed'
    },
  },
})

export default conditionsSlice.reducer
