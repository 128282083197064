// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

export const getConnector = createAsyncThunk(
  'connectors/getConnector',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get('/admin/pbiconnector/getAllConnector')
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createConnector = createAsyncThunk(
  'connectors/createConnector',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.post('/admin/pbiconnector/configPbiConnect', data)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateConnector = createAsyncThunk(
  'connectors/updateConnector',
  async (data, {rejectWithValue}) => {
    console.log(data)
    try {
      const r = await bicloudAxios.put(
        `/admin/pbiconnector/updatePbiConnect/${data.id}`,
        data.connector,
      )
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const syncReports = createAsyncThunk(
  'connectors/syncReports',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.post('/admin/reports/syncReports')
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const reportsSlice = createSlice({
  name: 'connectors',
  initialState: {
    connector: null,
    status: null,
  },
  extraReducers: {
    [getConnector.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getConnector.fulfilled]: (state, action) => {
      state.connector = action.payload[0]
      state.status = 'success'
    },
    [getConnector.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [createConnector.pending]: (state, action) => {
      state.status = 'loading'
    },
    [createConnector.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [createConnector.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateConnector.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateConnector.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [updateConnector.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [syncReports.pending]: (state, action) => {
      state.status = 'syncReportsLoading'
    },
    [syncReports.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [syncReports.rejected]: (state, action) => {
      state.status = 'failed'
    },
  },
})

export default reportsSlice.reducer
