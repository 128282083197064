// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

export const updateFilterAssignments = createAsyncThunk(
  'filterAssignments/updateFilterAssignments',
  async (data, {rejectWithValue}) => {
    try {
      await bicloudAxios.put(
        `/admin/assignments/filters/updateAllUserFilters/${data.userId}`,
        data.assignments,
      )

      const r = await bicloudAxios.get(
        `/admin/assignments/filters/getAllUserFilters/${data.userId}`,
      )

      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateFilterAssignmentsByReport = createAsyncThunk(
  'filterAssignments/updateFilterAssignmentsByReport',
  async (data, {rejectWithValue}) => {
    try {
      await bicloudAxios.put(
        `/admin/assignments/filters/updateAllUserFiltersInReport/${data.reportId}`,
        data.assignments,
      )

      const r = await bicloudAxios.get(
        `/admin/assignments/filters/getAllUserFiltersInReport/${data.reportId}`,
      )

      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createFilterAssignments = createAsyncThunk(
  'filterAssignments/createFilterAssignments',
  async (data, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.post('/admin/assignments/filters/create', data)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getFilterAssignments = createAsyncThunk(
  'filterAssignments/getFilterAssignments',
  async (id, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get(`/admin/assignments/filters/getAllUserFilters/${id}`)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getFilterAssignmentsByReport = createAsyncThunk(
  'filterAssignments/getFilterAssignmentsByReport',
  async (id, {rejectWithValue}) => {
    try {
      const r = await bicloudAxios.get(`/admin/assignments/filters/getAllUserFiltersInReport/${id}`)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const filterAssignmentsSlice = createSlice({
  name: 'filterAssignments',
  initialState: {
    list: [],
    status: null,
  },
  extraReducers: {
    [updateFilterAssignments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateFilterAssignments.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [updateFilterAssignments.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateFilterAssignmentsByReport.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateFilterAssignmentsByReport.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [updateFilterAssignmentsByReport.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [createFilterAssignments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [createFilterAssignments.fulfilled]: (state, action) => {
      state.status = 'success'
    },
    [createFilterAssignments.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getFilterAssignments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getFilterAssignments.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [getFilterAssignments.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getFilterAssignmentsByReport.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getFilterAssignmentsByReport.fulfilled]: (state, action) => {
      state.list = action.payload
      state.status = 'success'
    },
    [getFilterAssignmentsByReport.rejected]: (state, action) => {
      state.status = 'failed'
    },
  },
})

export default filterAssignmentsSlice.reducer
